<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit" >创建</el-button>
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="name">
          <el-input v-model.trim="table.params.name" placeholder="标签名称">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>编辑</el-button>
        <span class="action-btn-line">|</span>
        <el-button type="text" @click='remove(row)'>删除</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'TagIndex',
  components: {
    VTable,
    Edit,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "标签名称", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/admin/tag/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/tag/delete', {id: row.id}).then(res => { 
              if(res.code === 1) {
                this.$message.success('操作成功！');
                this.getTable();
              } else {
                this.$message.error(res.msg)
              }
            })
            done()
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
